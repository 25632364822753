
import Vue from "vue";
import store from "@/store";

import Chart from "chart.js/auto";
import API from "@/api/API";

export default Vue.extend({
  name: "work-positions",

  data: () => ({
    Chart,
    chartConfig: {
      servicesChart: {
        type: "pie",
        responsive: true,
        data: {} as any,
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      },
      statusesChart: {
        type: "bar",
        data: {},
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top"
            }
          }
        }
      },
      onlineVsOfflineChart: {
        type: "bar",
        data: {},
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top"
            }
          }
        }
      },
      monthsChart: {
        type: "bar",
        data: {} as any,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top"
            }
          }
        }
      },
      lastMonthChart: {
        type: "line",
        data: {} as any,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top"
            },
            title: {
              display: true
            }
          }
        }
      }
    } as any
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const [
        servicesChart,
        statusesChart,
        onlineVsOfflineChart,
        monthsChart,
        lastMonthChart
      ] = await Promise.all([
        API.statistics().getForServices(),
        API.statistics().getForLast30Days(),
        API.statistics().getForOnlineVsOffline(),
        API.statistics().getForLast12Months(),
        API.statistics().getForEverydayFor30Days()
      ]);

      next(vm => {
        vm.chartConfig.servicesChart.data = servicesChart;
        vm.chartConfig.statusesChart.data = statusesChart;
        vm.chartConfig.onlineVsOfflineChart.data = onlineVsOfflineChart;
        vm.chartConfig.monthsChart.data = monthsChart;
        vm.chartConfig.lastMonthChart.data = lastMonthChart;
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  mounted() {
    this.setCharts();
  },

  methods: {
    setCharts() {
      this.drawChart("servicesChart");
      this.drawChart("statusesChart");
      this.drawChart("onlineVsOfflineChart");
      this.drawChart("monthsChart");
      this.drawChart("lastMonthChart");
    },
    drawChart(chartID: any) {
      const ctx = (document.getElementById(chartID) as any).getContext("2d");
      new this.Chart(ctx, this.chartConfig[chartID]);
    }
  }
});
